import axios from 'axios'
import {API_URL} from "../../../helpers/crud-helper/consts";
const CONTACT_URL = `${API_URL}/contact`


const getContactPage = (): Promise<any> => {
    return axios
        .get(`${CONTACT_URL}`)
        .then((response) => response)
}

export {getContactPage}
