import React, {useEffect, useRef, useState} from "react";
import {useHeaderContext} from "../../layout/context/HeaderContext";
import {SocialMeta} from "../../components/SocialMeta";
import {getListYourPropertyPage} from "./core/requests";
import PropertyEvaluatorForm from "./components/PropertyEvaluatorForm";
import {Accordion} from "react-bootstrap";


const PropertyEvaluator = () => {
    const contactFormRef = useRef<HTMLDivElement>(null);
    const {setVariant, setLogoColor} = useHeaderContext();
    const [metaTags, setMetaTags] = useState<any>();
    const [activeKey, setActiveKey] = useState<string[]>([]);

    useEffect(() => {
        // Set variant to "default" when component mounts (assuming homepage needs transparent background)
        setVariant("default");
        setLogoColor("white")
        return () => {
            // Clean up or reset when component unmounts
            setVariant("default");
            setLogoColor("white");
        };
    }, [setVariant, setLogoColor]);
    const scrollToContactForm = () => {
        if (contactFormRef.current) {
            contactFormRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };
    // useEffect(() => {
    //     getListYourPropertyPage().then((response) => {
    //         setMetaTags(response.data.page)
    //     });
    // }, []);

    const faqs = [
        {question : "What is the Property Assessor, and how does it work?" , answer: "The Property Assessor is an online tool that provides an estimated market value of your property based on current real estate trends, recent transactions, and key property details you provide."},
        {question : "Is the valuation accurate?" , answer: "Our tool uses AI-powered analysis and market data to give an estimate, but for the most precise valuation, we recommend a professional consultation with our experts."},
        {question : "How long does it take to get my property valuation?" , answer: "The estimate is generated instantly after you enter your property details."},
        {question : "Is this tool free to use?" , answer: "Yes! Our Property Assessor is completely free to use."},
        {question : "What information do I need to provide for an accurate estimate?" , answer: "You’ll need to enter details like property type, location, number of bedrooms, size (sqft), condition, and additional features like a balcony or parking."},
        {question : "Does my property type affect the valuation?" , answer: "Yes, different property types (apartments, villas, townhouses, commercial spaces) have different market values based on location and demand."},
        {question : "What if my location is not listed?" , answer: "We currently support major areas in Dubai. If your location isn’t listed, please contact us for a manual evaluation."},
        {question : "How does the tool calculate my property’s value?" , answer: "It analyzes recent sales, market trends, and property features to estimate a competitive price."},
        {question : "Why is my estimated value different from other websites?" , answer: "Different platforms use unique datasets and valuation models. Our tool is optimized for Dubai’s real estate market, ensuring local accuracy."},
    ];

    const handleToggle = (key: string) => {
        if (activeKey.includes(key)) {
            setActiveKey(activeKey.filter(k => k !== key));
        } else {
            setActiveKey([...activeKey, key]);
        }
    };
    return (
        <>
            <SocialMeta
                title={metaTags && metaTags.meta_title ? metaTags.meta_title : 'Property Assessor'}
                description={metaTags && metaTags.meta_description ? metaTags.meta_description : 'We\'re here to make selling your property a smooth and rewarding experience.'}/>


            <section>
                <div className={'banner-block position-relative'}>
                    <img src={'/assets/images/property-evaluator/banner.jpg'} className={'banner w-100'}
                         alt={'List your property with us | Olive Branch'}/>
                    <div className={'block-center text-white text-center mt-lg-5'}>
                        <h1 className={'fs-65'}>Find Out Your Property’s <br/> Worth in Dubai!</h1>
                        <p>Get an instant estimate based on market trends and property data</p>
                        <div className={'mt-4'}>
                            <button onClick={scrollToContactForm} className={'btn btn-primary lg'}> Assess Now
                            </button>
                        </div>
                    </div>
                </div>

                <div className={'bg-form mb-5'} ref={contactFormRef}>
                    <div className={'container'}>
                        <PropertyEvaluatorForm/>
                    </div>

                    <div className={'aside-placeholder-left d-lg-block d-md-none d-none'}>
                        <img src={'/assets/images/list-property/frame-big.png'} alt="Olive Branch"/>
                    </div>
                </div>

                <div className={'mb-5'}>
                    <div className={'container'}>
                        <div className={'row justify-content-center align-items-center'}>
                            <div className={'col-lg-5 mt-2'}>
                                <div>
                                    <img src={'/assets/images/property-evaluator/building.png'} className={'w-100'}
                                         alt={'Olive Branch'}/>
                                </div>
                            </div>
                            <div className={'ps-lg-4 col-lg-4 mt-lg-1 mt-5'}>
                                <div>
                                    <h3 className={'text-main'}>How It Works ?</h3>
                                </div>
                                <div className={'mt-4'}>
                                    <div className={'d-flex  mt-2'}>
                                        <div>
                                            <img src={'/assets/images/property-evaluator/1st.png'} className={'me-3'}
                                                 width={'40'} alt={'Olive Branch'}/>
                                        </div>
                                        <div className={'pe-lg-5 '}>
                                            <h6 className={'form-label-secondary'}>Step 1:
                                            </h6>
                                            <p className={'pb-4 light-grey fs-12 pe-lg-5'}>
                                                Fill in your property details
                                            </p>
                                        </div>
                                    </div>
                                    <div className={'d-flex  mt-3'}>
                                        <div>
                                            <img src={'/assets/images/property-evaluator/2nd.png'} className={'me-3'}
                                                 width={'40'} alt={'Olive Branch'}/>
                                        </div>
                                        <div className={'pe-lg-5 '}>
                                            <h6 className={'form-label-secondary'}>Step 2: </h6>
                                            <p className={'pb-4 light-grey fs-12 pe-lg-5'}>
                                                Our evaluator compares market trends
                                            </p>
                                        </div>
                                    </div>
                                    <div className={'d-flex mt-3'}>
                                        <div>
                                            <img src={'/assets/images/property-evaluator/3rd.png'} className={'me-3'}
                                                 width={'40'} alt={'Olive Branch'}/>
                                        </div>
                                        <div className={'pe-lg-5 '}>
                                            <h6 className={'form-label-secondary'}>Step 3: </h6>
                                            <p className={'pb-4 light-grey fs-12 pe-lg-5'}>
                                                Receive an instant estimated value
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'bg-s position-relative'}>
                    <div className={'container'}>
                        <div className={'row mt-5'}>
                            <div className={'col-md-12 text-center pt-5 mb-5'}>
                                <h3 className={'text-main-lighter'}>Ready to take the next step?</h3>
                                <p className={'p-lighter'}>Connect with us today and let Olive Branch help you achieve
                                                           your property goals!</p>

                                <div className={'mt-4'}>
                                    <button className={'btn btn-primary lg'} onClick={scrollToContactForm}> Contact Us
                                                                                                            now
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'aside-placeholder d-lg-block d-md-none d-none'}>
                        <img src={'/assets/images/list-property/frame-right.png'} alt="Olive Branch"/>
                    </div>
                    <div className={'aside-placeholder-left d-lg-block d-md-none d-none'}>
                        <img src={'/assets/images/list-property/frame-left.png'} alt="Olive Branch"/>
                    </div>
                </div>

                <section id="faq-section" className="faq-section border-bottom">
                    <div className="container">
                        <div className="row align-items-center position-relative">
                            <div>
                                <h3 className={'text-main'}> FAQs</h3>
                            </div>
                            <div className="col-12">
                                <Accordion activeKey={activeKey}>
                                    {faqs.map((item, index) => {
                                        const key = `faqs-${index}`;
                                        return (
                                            <Accordion.Item eventKey={key} key={key}>
                                                <Accordion.Header onClick={() => handleToggle(key)}>
                                                    {item.question}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p dangerouslySetInnerHTML={{__html: item.answer}}></p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        );
                                    })}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </section>

            </section>


        </>
    );
};

export default PropertyEvaluator;
